import React from "react";

import { makeStyles, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(() => ({
  AlertDialogWrapper: {},
}));

const AlertDialog = ({
  variant = "success", // variant= "success" | "error"
  dialogTitle,
  open,
  handleClose,
  handleSubmit,
  submitLabel = "Submit",
  handleCancel,
  cancelLabel = "Cancel",
  children,
  maxWidth="sm"
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      className={classes.PaymentAlertDialogWrapper}
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle
        style={{
          color: theme.palette.common.white,
          backgroundColor:
            variant === "error"
              ? theme.palette.error.dark
              : theme.palette.success.dark,
          padding: theme.spacing(1),
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{dialogTitle}</Typography>
          <IconButton onClick={handleClose}>
            <CancelIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {handleSubmit && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {submitLabel}
          </Button>
        )}
        {handleCancel && (
          <Button
            onClick={handleCancel}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
