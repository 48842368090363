import firebase from "../utils/firebase";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../services/customerService";
import { updateLocalStorage } from "./UpdateLocalStorage";
import { setLocalStore } from "./utils";
import { setIsOpenLoginModal } from "../redux/actions/auth";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Facebook sign-in. Please use Facebook to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

export const AppleSignInUser = async (
  isNeedDisabled,
  setIsLoading,
  setAuthError,
  history,
  params,
  location,
  dispatch,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setAppleFirstName,
  setToken,
  isActiveLoyalty,
) => {
  if (isNeedDisabled) return;

  try {
    resetErrorState(setAuthError, setIsLoading);
    setIsLoading(true);

    const user = await handleAppleSignIn();
    if (!user) throw new Error("Apple Sign-In failed");
    const token = await user.getIdToken(true);

    await processCustomerValidation(
      token,
      user,
      history,
      params,
      location,
      dispatch,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setAppleFirstName,
      setToken,
      isActiveLoyalty,
    );
  } catch (error) {
    handleAuthError(error, setAuthError);
  } finally {
    setIsLoading(false);
  }
};

// Helper function to reset errors and loading states
const resetErrorState = (setAuthError, setIsLoading) => {
  setAuthError("");
  setIsLoading(true);
};

// Handle Apple Sign-In
const handleAppleSignIn = async () => {
  try {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");

    const result = await firebase.auth().signInWithPopup(provider);
    return result.user;
  } catch (error) {
    console.log("error", error);
  }
};

// Helper function to validate customer and handle post-auth actions
const processCustomerValidation = async (
  token,
  user,
  history,
  params,
  location,
  dispatch,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setAppleFirstName,
  setToken,
  isActiveLoyalty,
) => {
  try {
    // Get the query parameter from the URL
    const paramsPopup = new URLSearchParams(window.location.search);
    const popup = paramsPopup.get("popup");
    const response = await getCustomerValidationNew(token); // Validate customer
    setLocalStore("customerId", response.data.data.id);
    setLocalStore("shortId", response.data.data.shortId);
    const fullName = user?.displayName || "";
    const [firstName, ...lastNameParts] = fullName.split(" ");
    const lastName = lastNameParts.join(" ") || "";

    if (!lastName) {
      promptForLastName(
        firstName,
        token,
        setIsEmptyLastName,
        setIssOpenFirstNameLastNameModal,
        setAppleFirstName,
        setToken,
      );
    } else {
      // await user.updateProfile({
      //   displayName: `${firstName} ${lastName}`,
      // });

      updateLocalStorage(
        token,
        dispatch,
        {
          email: user.email,
          uid: user.uid,
          firstName: firstName,
          lastName: lastName,
        },
        history,
        params,
        location,
        popup,
      );
    }
  } catch (error) {
    await handleCustomerRegistration(
      token,
      user,
      history,
      params,
      location,
      dispatch,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setAppleFirstName,
      setToken,
    ); // Register customer if validation fails
  }
};

// Helper function to log successful sign-in
const logSignInSuccess = (user) => {
  logInfo({
    message: `GoogleSignIn: User [name: ${user.displayName}, uid: ${user.uid}] login success`,
  });
};

// Helper function to handle redirection after authentication
const handlePostAuthRedirect = (
  history,
  params,
  location,
  token,
  user,
  dispatch,
  firstName,
  lastName,
  isActiveLoyalty,
) => {
  // Get the query parameter from the URL
  const paramsPopup = new URLSearchParams(window.location.search);
  const popup = paramsPopup.get("popup");

  updateLocalStorage(
    token,
    dispatch,
    {
      email: user.email,
      uid: user.uid,
      firstName: firstName,
      lastName: lastName,
    },
    history,
    params,
    location,
    popup,
  );
};

// Helper function to handle customer registration if validation fails
const handleCustomerRegistration = async (
  token,
  user,
  history,
  params,
  location,
  dispatch,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setAppleFirstName,
  setToken,
  isActiveLoyalty,
) => {
  const fullName = user?.displayName || "";
  const [firstName, ...lastNameParts] = fullName.split(" ");
  const lastName = lastNameParts.join(" ") || "";
  if (!lastName) {
    promptForLastName(
      firstName,
      token,
      setIsEmptyLastName,
      setIssOpenFirstNameLastNameModal,
      setAppleFirstName,
      setToken,
    );
  } else {
    await registerNewCustomer(
      token,
      firstName,
      lastName,
      user,
      history,
      params,
      location,
      dispatch,
      isActiveLoyalty,
    );
  }
};

// Helper function to prompt user for last name if missing
const promptForLastName = (
  firstName,
  token,
  setIsEmptyLastName,
  setIssOpenFirstNameLastNameModal,
  setAppleFirstName,
  setToken,
) => {
  setIsEmptyLastName(true);
  setIssOpenFirstNameLastNameModal(true);
  setAppleFirstName(firstName);
  setToken(token);
};

// Helper function to register a new customer
const registerNewCustomer = async (
  token,
  firstName,
  lastName,
  user,
  history,
  params,
  location,
  setIsLoading,
  dispatch,
  isActiveLoyalty,
) => {
  // Get the query parameter from the URL
  const paramsPopup = new URLSearchParams(window.location.search);
  const popup = paramsPopup.get("popup");

  try {
    const response = await customerUpdateNew(token, firstName, lastName);
    setLocalStore("customerId", response.data.data.id);
    setLocalStore("shortId", response.data.data.shortId);
    setLocalStore("idToken", token);
    setLocalStore("userId", user.uid);
    setLocalStore("photoURL", user?.photoURL);

    if (!popup) {
      dispatch(setIsOpenLoginModal(false));
    }
    logSignInSuccess({
      displayName: `${firstName} ${lastName}`,
      uid: firebase.auth().currentUser?.uid,
    });

    handlePostAuthRedirect(
      history,
      params,
      location,
      token,
      user,
      dispatch,
      firstName,
      lastName,
      isActiveLoyalty,
    );
  } catch (error) {}
};

// Helper function to handle authentication errors
const handleAuthError = (error, setAuthError) => {
  const { email, credential, code: errorCode, message: errorMessage } = error;
  const isIdbError = errorMessage.includes("IDBDatabase");

  const message = isIdbError
    ? ERROR_MESSAGE_IDB_DATABASE
    : errorCode === "auth/account-exists-with-different-credential"
    ? ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST
    : errorMessage;

  setAuthError(message);
  logError({
    message: "GoogleSignIn: Error login user with google sign in",
    error,
    email,
    credential,
    errorCode,
  });
};

// Log and Error Utility
const logError = (errorDetails) => {
  // Implementation for logging errors
  console.error(errorDetails);
};

const logInfo = (infoDetails) => {
  // Implementation for logging info
  console.info(infoDetails);
};
