/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { Button, makeStyles, Typography, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    "&.MuiButton-root": {
      // borderRadius: 4,
    },
    "&.Mui-disabled": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.disabled,
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const ButtonContained = ({
  onClick,
  flex = "none",
  variant = "contained",
  color = "secondary",
  height = "56px",
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      color={color}
      variant={variant}
      classes={{ root: classes.root }}
      style={{ flex, height }}
      onClick={onClick}
      {...props}
    >
      <Typography variant="button">{children}</Typography>
    </Button>
  );
};

export default ButtonContained;
