import { isFunction } from "lodash";
import { loginUserSuccess, setIsOpenLoginModal } from "../redux/actions/auth";
import {
  customerUpdateNew,
  getCustomerValidationNew,
} from "../services/customerService";
import firebase from "../utils/firebase";
import { setLocalStore } from "./utils";
import { fetchLoyaltyListAsync } from "../components/@pages/storeHome/CatMenu/redux/actions";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

export const RegistrationNew = async (
  setIsLoading,
  customer,
  setAuthError,
  dispatch,
  params,
  logError,
  setCustomerDetails,
  setIsLoginOrRegister,
) => {
  try {
    // Set persistence for the session
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    setIsLoading(true);

    // Register user with email and password
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(customer.email, customer.password);
    const firebaseUser = result.user;

    // Handle email verification
    if (!firebaseUser.emailVerified) {
      await handleEmailVerification(firebaseUser, customer, setCustomerDetails);
    }

    const idToken = await firebaseUser.getIdToken(true);
    await handlePostRegistration(
      idToken,
      firebaseUser,
      customer,
      dispatch,
      params,
      setIsLoading,
      setIsLoginOrRegister,
    );
  } catch (error) {
    if (error.code === "auth/weak-password") {
      handleError(error, setAuthError, logError, setIsLoading);
    } else {
      // Handle errors during registration or persistence
      await handleRegistrationInFallback(
        customer,
        dispatch,
        params,
        setIsLoading,
        setAuthError,
        logError,
        setIsLoginOrRegister,
      );
    }
  }
};

// Helper function to handle email verification
const handleEmailVerification = async (
  firebaseUser,
  customer,
  setCustomerDetails,
) => {
  await firebaseUser.sendEmailVerification();
  setCustomerDetails({
    firstName: customer.firstName,
    lastName: customer.lastName,
    email: firebaseUser.email,
  });
};

// Helper function to handle post-registration actions
const handlePostRegistration = async (
  idToken,
  firebaseUser,
  customer,
  dispatch,
  params,
  setIsLoading,
  setIsLoginOrRegister,
) => {
  // Get the query parameter from the URL
  const paramsPopup = new URLSearchParams(window.location.search);
  const popup = paramsPopup.get("popup");

  try {
    // Attempt to validate the customer in your database
    const response = await getCustomerValidationNew(idToken);
    setLocalStore("customerId", response.data.data.id);
    setLocalStore("shortId", response.data.data.shortId);
    // Store the token and update the user state
    setLocalStore("idToken", idToken);

    dispatch(fetchLoyaltyListAsync(params.locationId, true));

    dispatch(
      loginUserSuccess(
        `${customer.firstName} ${customer.lastName}`,
        firebaseUser.email,
      ),
    );
    await firebaseUser.updateProfile({
      displayName: `${customer.firstName} ${customer.lastName}`,
    });
    // Handle redirection and callback
    handleRedirection(setIsLoading, dispatch, popup, setIsLoginOrRegister);
  } catch (error) {
    // Handle user not found (404) by adding the user to the database
    try {
      const response = await customerUpdateNew(
        idToken,
        customer.firstName,
        customer.lastName,
      );
      setLocalStore("customerId", response.data.data.id);
      setLocalStore("shortId", response.data.data.shortId);
      setLocalStore("idToken", idToken);

      dispatch(fetchLoyaltyListAsync(params.locationId, true));

      dispatch(
        loginUserSuccess(
          `${customer.firstName} ${customer.lastName}`,
          firebaseUser.email,
        ),
      );
      await firebaseUser.updateProfile({
        displayName: `${customer.firstName} ${customer.lastName}`,
      });

      handleRedirection(setIsLoading, dispatch, popup, setIsLoginOrRegister);
    } catch (error) {}
  }
};

const handleRegistrationInFallback = async (
  customer,
  dispatch,
  params,
  setIsLoading,
  setAuthError,
  logError,
  setIsLoginOrRegister,
) => {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(customer.email, customer.password);
    const firebaseUser = result.user;

    if (firebaseUser) {
      const token = await firebaseUser.getIdToken(true);
      await handlePostRegistration(
        token,
        firebaseUser,
        customer,
        dispatch,
        params,
        setIsLoading,
        setIsLoginOrRegister,
      );
    }
  } catch (error) {
    handleError(error, setAuthError, logError, setIsLoading); // Handle sign-in errors
  }
};

// Helper function to handle redirection and auth callbacks
const handleRedirection = (
  setIsLoading,
  dispatch,
  popup,
  setIsLoginOrRegister,
) => {
  if (window.authCallback && isFunction(window.authCallback)) {
    window.authCallback();
    window.authCallback = null;
  }
  if (!popup) {
    dispatch(setIsOpenLoginModal(false));
  }
  setIsLoginOrRegister(true);
  setIsLoading(false);
};

// Helper function to handle error logging and UI feedback
const handleError = (error, setAuthError, logError, setIsLoading) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  const isIdbError = errorMessage.includes("IDBDatabase");
  const message = isIdbError ? ERROR_MESSAGE_IDB_DATABASE : errorMessage;

  setAuthError(message);
  logError({
    message: "Firebase operation failed",
    error,
    email: error?.email,
    errorCode,
  });

  setIsLoading(false);
};
