import axios from "axios";
import axiosClient from "./client";

export function getAllLocationInfo(locationId, size, currentPage) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/public-auth/customer`,
  });
}

export function getCustomerValidation(email) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/internal/customer/search/${email}`,
  });
}

export function getCustomerValidationNew(token) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/public-auth/customer/marketing`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function customerUpdateNew(token, firstName, lastName) {
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/public-auth/customer/get-or-create/${firstName}/${lastName}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function customerUpdate(email, firstName, lastName) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/internal/customer/search/${email}/${firstName}/${lastName}`,
  });
}
