/* eslint-disable no-useless-escape */
export const DISCOUNT_TYPE_AMOUNT = "amount";
export const DISCOUNT_TYPE_PERCENTAGE = "percent";
// export const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
export const EMAIL_REGEX_PATTERN = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const EMAIL_REGEX_PATTERN_SIMPLE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const UK_PHONE_NUMBER_REGEX_PATTERN = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const REGEX_PATTERN_HTML_STRING = /<.+>/;

export const TIME_FORMAT_YYYYMMDD_HHMMSS = "YYYY-MM-DD HH:mm:ss";
export const TIMEZONE_ASIA_COLOMBO = "Asia/Colombo";
export const TIMEZONE_EUROPE_LONDON = "Europe/London";
export const TIME_FORMAT_SERVER = "YYYY-MM-DD HH:mm:ss.SSS";

export const DELIVERY_TYPE_DINEIN = "dinein";
export const DELIVERY_TYPE_PICKUP = "takeout";

export const DELIVERY_TYPE_TAKEOUT = "takeout";
export const DELIVERY_TYPE_DELIVER = "delivery";

export const ASAP_TIME_LABEL = "ORDER FOR NOW";
export const SPECIFIC_TIME_LABEL = "SCHEDULE";

export const SERVICE_FEE_MESSAGE = "Eat Presto Ordering Service Fee";
export const CARRIER_BAG_CHARGE_MESSAGE = "From 30th April 2021, government regulations require businesses to charge for the supply of single-use carrier bags.";

export const HOME_ICON_URL = "https://firebasestorage.googleapis.com/v0/b/level-prism-276923.appspot.com/o/home64.png?alt=media&token=c698bb2b-c0ac-4d59-8795-fb58e19dccb7";
export const RESTAURANT_ICON_URL = "https://firebasestorage.googleapis.com/v0/b/level-prism-276923.appspot.com/o/restaurant64.png?alt=media&token=48f4d77a-3106-4617-ae27-b5b36d3fce0f";

export const MENU_VIEW_TYPE_LIST = "simple_item";
export const MENU_VIEW_TYPE_GRID = "simple_grid";
export const MENU_VIEW_TYPE_IMAGE_LIST = "horiz_grid";

export const TEXT_LIST_OPTION = "text_list_option"
export const IMAGE_LIST_OPTION = "image_list_option"
export const IMAGE_GRID_OPTION ="image_grid_option"

export const PAYMENT_METHOD_CARD = "card";
export const PAYMENT_METHOD_CASH = "cash";

// modal open query params
export const QUERY_PARAM_POPUP_MENU = "popupMenu";
export const QUERY_PARAM_POPUP_CAT = "popupCategory";
export const QUERY_PARAM_POPUP_CATMENU = "popupBrand";

export const PaymentRules = {
  ssr_1JRuZbEu0XseDNv1Nqb3j6s9: {
    error1: "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBI64Eu0XseDNv17FA1vVKR: {
    error: "Transaction Declined.  Non UK cards are not accepted. Please try again with a UK registered debit or credit card.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBIs6Eu0XseDNv1pUTeFlhf: {
    error1: "Transaction Declined. Your region seems to be outside of the UK.",
    error2: "Please try Apple or Google Pay.",
  },

  ssr_1JBI8DEu0XseDNv1DGUyCKyx: {
    error1: "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBI5PEu0XseDNv1F5mK9e2e: {
    error1: "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay",
  },

  ssr_1JBIYEEu0XseDNv1jOoWfr9y: {
    error1: "Transaction Declined. Invalid CVC number provided. Please try again with the correct CVC.",
    error2: "Alternatively please try Apple or Google Pay.",
  },
};

// errors
export const ERROR_UNEXPECTED_CHECK_CONNECTION = "Unexpected Error. Please check your connection.";
