import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import { Icon, IconButton, makeStyles } from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";

import { TIME_FORMAT_YYYYMMDD_HHMMSS } from "../../../../../utils/constants";
import moment from "../../../../../utils/time/moment";
import { setSelectedDateTime } from "../redux/actions";
import OpenTimeList from "./OpenTimeList";

const useStyles = makeStyles((theme) => ({
  TimePickerWrapper: {},
  pickerDialog: {
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
        marginTop: theme?.view?.isFixedList ? "24px" : "initial",
      },
    },
    "& .MuiButton-textPrimary": {
      color: theme.palette.text.primary,
    },
  },
  timePickerRoot: {
    "& .MuiFormLabel-root": {
      color: theme.palette.text.primary,
    },
  },
}));

const PrestoTimePicker = ({
  handleTimeChange,
  minimumDelayMin,
  scheduleDelay,
  selectedDateTime,
  setSelectedDateTimeDetails,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDec = () => {
    const time = moment(selectedDateTime);
    const remainder = time.minute() % 15;
    let diff = 0;
    if (!remainder) diff = 15;
    else diff = remainder;
    time.subtract(diff, "minutes");
    const timeGap = time.diff(moment(), "minutes");
    if (timeGap < minimumDelayMin) return;
    // dispatch(setSelectedDateTime(time.format(TIME_FORMAT_YYYYMMDD_HHMMSS)));
    setSelectedDateTimeDetails(time.format(TIME_FORMAT_YYYYMMDD_HHMMSS));
  };

  const handleInc = () => {
    const time = moment(selectedDateTime);
    const remainder = 15 - (time.minute() % 15);

    time.add(remainder, "minutes");
    const timeGap = time.diff(moment(), "minutes");
    if (timeGap < minimumDelayMin) {
      const fixedTime = moment(selectedDateTime);
      fixedTime.add(minimumDelayMin, "minutes");
      const remainderFixed = 15 - (fixedTime.minute() % 15);
      fixedTime.add(remainderFixed, "minutes");
      // return dispatch(
      //   setSelectedDateTime(fixedTime.format(TIME_FORMAT_YYYYMMDD_HHMMSS)),
      // );
      setSelectedDateTimeDetails(fixedTime.format(TIME_FORMAT_YYYYMMDD_HHMMSS));
    }
    setSelectedDateTimeDetails(time.format(TIME_FORMAT_YYYYMMDD_HHMMSS));
    // dispatch(setSelectedDateTime(time.format(TIME_FORMAT_YYYYMMDD_HHMMSS)));
  };

  return (
    <div className={classes.TimePickerWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          padding: 0,
        }}
      >
        <IconButton
          size="medium"
          color="secondary"
          style={{ padding: "0", marginRight: 4, maxHeight: 32 }}
          aria-label="decrement"
          onClick={handleDec}
        >
          <Icon fontSize="large">remove_circle</Icon>
        </IconButton>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker
            ampm={false}
            variant="dialog"
            label="Select time"
            value={new Date(selectedDateTime)}
            minutesStep={1}
            onChange={handleTimeChange}
            inputVariant="filled"
            className={classes.timePickerRoot}
            DialogProps={{ className: classes.pickerDialog }}
          />
        </MuiPickersUtilsProvider>
        <IconButton
          color="secondary"
          style={{ padding: "0", marginLeft: 4, maxHeight: 32 }}
          aria-label="decrement"
          onClick={handleInc}
          size="medium"
        >
          <Icon fontSize="large">add_circle</Icon>
        </IconButton>
      </div>
      <OpenTimeList
        selectedDateTime={selectedDateTime}
        scheduleDelay={scheduleDelay}
      />
    </div>
  );
};

PrestoTimePicker.propTypes = {};
PrestoTimePicker.defaultProps = {};

export default PrestoTimePicker;
