import React from "react";

import StorefrontIcon from "@material-ui/icons/Storefront";
import AlertDialog from "../../../../common/dialogs/AlertDialog";
import { DialogContent, Typography } from "@material-ui/core";

const RewardModal = ({
  stampedSpaces,
  state,
  noOfSpaces,
  handleCircle,
  open,
  handleClose,
}) => {
  return (
    <AlertDialog
      variant="success"
      dialogTitle="Redeem Your Reward In-Store"
      open={open}
      handleClose={handleClose}
      submitLabel="ORDER STATUS PAGE"
      maxWidth="xs"
    >
      <DialogContent>
        <Typography variant="body2" style={{ marginBottom: "24px" }}>
          This reward is redeemable in-store only. Please scan your QR code to
          claim your reward.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Typography
            variant="body2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <StorefrontIcon style={{ paddingRight: "4px", fontSize: "22px" }} /> Ready to Redeem
            In-Store
          </Typography>
        </div>
        {handleCircle(noOfSpaces, state, stampedSpaces)}
      </DialogContent>
    </AlertDialog>
  );
};

export default RewardModal;
