import _ from "lodash";

import moment from "./time/moment";
import { isToday } from "date-fns";

/**
 * HANDLING DST (Daylight Saving Time) WHEN CONVERTING MINUTE OF WEEK(MOW) TO TIME
 * ---------------------------------------------------------------------------------------------
 * How we convert MOW to time is fist we take the current week starting Sunday and then we add the
 * MOW number by dividing the number by 60 to make it hours and that from the 24 to make it day.
 * From that we calculate the day, hour and minute of the moment object. which means we assume
 * 1440 minutes per day.
 *
 * As according to MOW we divide the week to 10080 minutes and each day has 1440 minutes.
 *
 * when DST starts, the last Sunday of March it will move 1 hour forward from 2am to 3am.
 * Due to this 1 hour forward shift now for the Sunday it has only the 1380(1440 - 60) minutes.
 *
 * So when DST happens when using Sunday midnight time (00.00.00) we need to use only 1380 minutes
 * for that day, which means we should subtract 60 minutes from the result time.
 *
 * Opposite to this, the reverse thing happens when DST ends
 * when DST ends, the last Sunday of October it will move 1 hour backward from 3am to 2am.
 * Due to this 1 hour backward shift now for the Sunday it has the 1500(1440 + 60) minutes.
 *
 * So when DST ends when using Sunday midnight time (00.00.00) we need to use 1500 minutes
 * for that day, which means we should add 60 minutes to result the time.
 *
 * NOTE: This issue only happens on DST starting and ending Sundays.For all other Sundays the number
 * of minutes per day is constant = 1440.
 */

const TimeUtils = {
  /**
   * So for the above minutes issue, we identify the DST starting sunday by checking
   * 1. is current time DST time
   * 2. is the start of week (sunday 00:00:00) DST time.
   *
   * 2 becomes false and 1 becomes true only happens in the DST starting week.
   * So we subtract 1 hour from calculated the time.
   *
   * and when DST ends we identify the DST ending sunday by same variables where
   * 2 becomes true and 1 becomes false which only happens on the DST ending week.
   * so we add 1 hour to the calculated time.
   *
   * @param {number} minuteOfWeek minute of week which need to convert to moment time object.
   * @param {moment | time-string} timeNow current time ( most of the time its: moment() )
   * @returns moment time object.
   */

  convertMinuteOfWeekToTime: (minuteOfWeek, timeNow) => {
    const weekStartSundayTime = moment(timeNow).startOf("week");
    const calculatedTime = moment(timeNow)
      .startOf("week")
      .add(minuteOfWeek, "minutes");

    // handle DST-START
    if (calculatedTime.isDST() && !weekStartSundayTime.isDST()) {
      return calculatedTime.subtract(1, "hour");
    }

    // handle DST-END
    if (!calculatedTime.isDST() && weekStartSundayTime.isDST()) {
      return calculatedTime.add(1, "hour");
    }
    return calculatedTime;
  },

  convertTimeToMinuteOfWeek: (time) => {
    const mow = time.day() * 1440 + time.hours() * 60 + time.minutes();
    return mow;
  },

  /**
   * check if the given time available in the weekly time array
   */
  isTimeInWeeklyTimesSchedule: (time, weeklyTimes, scheduleDelay) => {
    const timeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(time);
    const currentMoment = moment();
    const currentTimeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(currentMoment);
    const today = isToday(time.toDate()); 
  
    return weeklyTimes.some(({ minuteOfWeek, durationMins }) => {
      const isWithinRange =
        timeMinuteOfWeek >= minuteOfWeek + scheduleDelay &&
        timeMinuteOfWeek < minuteOfWeek + durationMins;
  
      if (!isWithinRange) return false; 

      return today ? timeMinuteOfWeek - currentTimeMinuteOfWeek >= scheduleDelay : true;
    });
  },

  /**
   * check if the given time available in the weekly time array
   */
  isTimeInWeeklyTimes: (time, weeklyTimes) => {
    let isTimeIn = false;
    const timeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(time);
    weeklyTimes.forEach((day) => {
      if (
        timeMinuteOfWeek >= day.minuteOfWeek &&
        timeMinuteOfWeek < day.minuteOfWeek + day.durationMins
      ) {
        isTimeIn = true;
      }
    });
    return isTimeIn;
  },

  isTimeInWeeklyTimesScheduleDelivery: (time, weeklyTimes, scheduleDelay) => {
    let isTimeIn = false;
    const timeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(time);
    console.log("Aaaaaaaaaaaaaaaaaaasddfdf", timeMinuteOfWeek, weeklyTimes, scheduleDelay)

    weeklyTimes.forEach((day) => {
      if (
        timeMinuteOfWeek >= day.minuteOfWeek + scheduleDelay &&
        timeMinuteOfWeek < day.minuteOfWeek + day.durationMins
      ) {
        isTimeIn = true;
      }
    });
    return isTimeIn;
  },

  /**
   * compare availability and opening times to check the given time
   * matches the availability and opening times
   */
  isTimeAvailable: (time, availability, openTimes) => {
    if (!_.isEmpty(availability)) {
      return TimeUtils.isTimeInWeeklyTimes(time, availability);
    }
    return TimeUtils.isTimeInWeeklyTimes(time, openTimes.weekly);
  },

  isTimeAvailableSchedule: (time, availability, openTimes, scheduleDelay) => {
    if (!_.isEmpty(availability)) {
      return TimeUtils.isTimeInWeeklyTimesScheduleDelivery(time, availability, scheduleDelay);
    }
    return TimeUtils.isTimeInWeeklyTimesScheduleDelivery(time, openTimes.weekly, scheduleDelay);
  },
};

export default TimeUtils;
