/* eslint-disable class-methods-use-this */
import _ from "lodash";

import moment from "./time/moment";
import TimeUtils from "./TimeUtils";

const AvailabilityUtils = {
  // availability utils for calculating item availability for
  // 1. item active status
  // 2. selected delivery method
  // 3. selected time

  getAvailabilityOptions: (options, menuID, catID, catMenuID) => ({
    itemOption: options[`M${menuID}`],
    catOption: options[`C${catID}`],
    catMenuOption: options[`CM${catMenuID}`],
  }),

  getIsItemActive: (options, menuID, catID, catMenuID) => {
    const { itemOption, catOption, catMenuOption } =
      AvailabilityUtils.getAvailabilityOptions(
        options,
        menuID,
        catID,
        catMenuID,
      );
    return _.every(
      _.filter(
        [itemOption?.isActive, catOption?.isActive, catMenuOption?.isActive],
        (isActive) => isActive !== undefined,
      ),
    );
  },

  getNextAvailableTime: (availability, timeNow) => {
    if (_.isEmpty(availability)) {
      return timeNow;
    }

    const timeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(timeNow);
    const filteredTimeList = availability
      .map((time) => time.minuteOfWeek)
      .filter((time) => time > timeMinuteOfWeek);
    if (!_.isEmpty(filteredTimeList)) {
      const nextOpenTime = _.min(filteredTimeList);
      return TimeUtils.convertMinuteOfWeekToTime(nextOpenTime, timeNow);
    }
    const nextOpenTime = _.sortBy(
      availability.map((time) => time.minuteOfWeek),
    )[0];
    const time = moment();
    time.add(1, "week").startOf("week").add(nextOpenTime, "minutes");
    return time;
  },

  getNextAvailableTimeSchedule: (availability, timeNow, scheduleDelay) => {
    if (_.isEmpty(availability)) {
      return timeNow;
    }
console.log("swwwwwwwwwwwwwwwwwfsdfsdf", timeNow)
    const timeMinuteOfWeek = TimeUtils.convertTimeToMinuteOfWeek(timeNow);
    const filteredTimeList = availability
      .map((time) => time.minuteOfWeek)
      .filter((time) => time + scheduleDelay > timeMinuteOfWeek);
    if (!_.isEmpty(filteredTimeList)) {
      const nextOpenTime = _.min(filteredTimeList) + scheduleDelay;
      return TimeUtils.convertMinuteOfWeekToTime(nextOpenTime, timeNow);
    }
    const nextOpenTime = _.sortBy(
      availability.map((time) => time.minuteOfWeek + scheduleDelay),
    )[0];
    const time = moment();
    time.add(1, "week").startOf("week").add(nextOpenTime, "minutes");
    return time;
  },

  /**
   * all the availability node times are in between restaurant open times.
   * so, if there is a non-empty availability node, then we need to check the
   * availability node to get the next available time comparing current time.
   * if availability node is empty we need to check opening times compare to current time.
   */
  getEarliestAvailableTimeForDeliveryOption: (
    availability,
    openTimes,
    timeNow,
    scheduleDelay,
  ) => {
    if (!_.isEmpty(availability)) {
      return AvailabilityUtils.getNextAvailableTimeSchedule(
        availability,
        timeNow,
        scheduleDelay,
      );
    }
    return AvailabilityUtils.getNextAvailableTimeSchedule(
      openTimes.weekly,
      timeNow,
      scheduleDelay,
    );
  },

  // eslint-disable-next-line max-len
  getNextOpeningDateTime: (openTimes, timeNow) =>
    AvailabilityUtils.getNextAvailableTime(openTimes, timeNow),

  getNotificationMessage: (
    isOrderingActive,
    isPreOrderActive,
    isOpenNow,
    openTimes,
  ) => {
    const nextOpenDateTime = AvailabilityUtils.getNextOpeningDateTime(
      openTimes,
      moment(),
    );
    if (isOrderingActive === false) {
      return "Sorry, we are not accepting online orders at the moment";
    }
    if (isPreOrderActive === false) {
      return `Sorry we are currently closed. Please come back ${nextOpenDateTime.calendar()}`;
    }
    return `Sorry we are currently closed. You can place an order for a later time, or come back ${nextOpenDateTime.calendar()}`;
  },
};

export default AvailabilityUtils;
