/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
/* eslint-disable no-console */

import _ from "lodash";

import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
  DELIVERY_TYPE_PICKUP,
  DISCOUNT_TYPE_AMOUNT,
  DISCOUNT_TYPE_PERCENTAGE,
} from "../constants";
import moment from "../time/moment";
import TimeUtils from "../TimeUtils";

/**
 * filter isActive discount items
 * @param {list} discountList
 */
export const filterDiscountsByIsActive = (discountList) => {
  if (!_.isArray(discountList)) return [];
  return [...discountList].filter((discount) => discount.isActive);
};

/**
 * filter automatic discount items
 * @param {list} discountList
 */
export const filterDiscountsByIsAutomatic = (discountList) => {
  if (!_.isArray(discountList)) return [];
  return [...discountList].filter((discount) => discount.isAutomaticDiscount);
};

/**
 *cal cart total and filter discounts by min order price equal or same
 * @param {*} cartItems
 * @param {*} discountList
 * @returns {list}
 */
export const filterDiscountsByMinOrderPrice = (cartItems, discountList) => {
  const cartTotal = _.sum(cartItems.map((c) => parseFloat(c.totalPrice)));
  return [...discountList].filter(
    (discount) => parseFloat(discount.minOrderPrice) <= cartTotal,
  );
};

/**
 * if avail availability array empty - do not remove the discount node
 * else if availability array is not empty, remove the nodes which are not
 * compatible with availability array
 * @param {string} deliveryTime selected pickup/delivery/dine-in time. format: YYYY-MM-DD HH:mm:ss
 * @param {list} discountList discount list
 */
export const filterDiscountsByAvailability = (deliveryTime, discountList) => {
  return [...discountList].filter((discount) => {
    if (_.isEmpty(discount.availability)) return true;
    return TimeUtils.isTimeInWeeklyTimes(
      moment(deliveryTime),
      discount.availability,
    );
  });
};

/**
 * filter list by selected delivery method(delivery, dine-in or pickup)
 * @param {string} selectedDeliveryMethod selected delivery method | PICKUP, DINEIN, DELIVER
 * @param {list} discountList discount list
 */
export const filterDiscountsByDeliveryType = (
  selectedDeliveryMethod,
  discountList,
) => {
  switch (selectedDeliveryMethod) {
    case "DINEIN":
      return [...discountList].filter((discount) =>
        discount.deliveryTypes.includes(DELIVERY_TYPE_DINEIN),
      );
    case "PICKUP":
      return [...discountList].filter((discount) =>
        discount.deliveryTypes.includes(DELIVERY_TYPE_PICKUP),
      );
    case "DELIVER":
      return [...discountList].filter((discount) =>
        discount.deliveryTypes.includes(DELIVERY_TYPE_DELIVER),
      );
    default:
      return [...discountList];
    // throw new Error("Selected Delivery Method does not match. Should be DINEIN | PICKUP | DELIVER");
  }
};

/**
 * group by discountGroup
 * normalize each group by
 *  if amount and percent -> filter percent max discount
 *   else if only amount discounts => filter max discount
 * return flattened the array
 * @param {list} discountList discount list
 */
export const filterDiscountsByDiscountGroups = (discountList) => {
  const groupedDiscounts = _.groupBy(
    [...discountList],
    (discount) => discount.discountGroup,
  );
  let normalizedList = [];
  // iter each discount group and get applicable discount node by above criteria
  Object.keys(groupedDiscounts).forEach((discountGroup) => {
    const groupDiscountList = groupedDiscounts[discountGroup];
    let applicableDiscount = {};
    const percentDiscountList = groupDiscountList.filter(
      (disc) => disc.type === DISCOUNT_TYPE_PERCENTAGE,
    );
    if (!_.isEmpty(percentDiscountList)) {
      applicableDiscount = _.maxBy(percentDiscountList, (disc) =>
        parseFloat(disc.value),
      );
    } else {
      // make sure backend sends only percent and amount types
      applicableDiscount = _.maxBy(groupDiscountList, (disc) =>
        parseFloat(disc.value),
      );
    }
    normalizedList = [...normalizedList, applicableDiscount];
  });
  return normalizedList;
};

const attachDiscountByItems = (cartItems, discount) => {
  return cartItems.map((cartItem) => {
    const discountAttachedCartItem = { ...cartItem };
    if (discount.applyTo.includes(cartItem.item.menuId)) {
      discountAttachedCartItem.applicableDiscounts = [
        ...cartItem.applicableDiscounts,
        discount,
      ];
    }
    return discountAttachedCartItem;
  });
};

const attachDiscountByCategories = (cartItems, discount) => {
  return cartItems.map((cartItem) => {
    const discountAttachedCartItem = { ...cartItem };
    if (discount.applyTo.includes(cartItem.item.catId)) {
      discountAttachedCartItem.applicableDiscounts = [
        ...cartItem.applicableDiscounts,
        discount,
      ];
    }
    return discountAttachedCartItem;
  });
};

/**
 * attach given discount on given cart items if applicable
 *
 * for each discount node
 *  if (applyToCondition === 'all' ) => for each item attache the discount node
 *  if (applyToCondition === 'items' ) => filter cartItems by "applyTo" by menuId  and attache the discount node
 *  if (applyToCondition === 'categories' ) => filter cartItems by "applyTo" by CATEGORY_ID list and attache the discount node
 *
 * CONDITION:
 *   if applyToCondition == 'all' AND if discountType == 'percent'
 *       attach each percent discount  with cartItem
 *   if applyToCondition == 'all' AND if discountType == 'amount'
 *       will NOT attach each amount discount  with cartItem
 *       as sum of amount discount applies at the end.
 *   if  applyToCondition == 'items' or 'cat'
 *       will attach the discount node without considering discount type
 * @param {list} cartItems cart items list
 * @param {object} discount discount node object
 */
const attachDiscountOnCartItems = (cartItemList, discount) => {
  switch (discount.applyToCondition) {
    case "all":
      // if (discount.type === DISCOUNT_TYPE_PERCENTAGE) {
      //   return cartItemList.map((item) => ({ ...item, applicableDiscounts: [...item.applicableDiscounts, discount] }));
      // }
      return cartItemList;
    case "items":
      return attachDiscountByItems(cartItemList, discount);
    case "categories":
      return attachDiscountByCategories(cartItemList, discount);
    default:
      return null;
  }
};

const aggregateLineItemsByItemId = (cartItemList) => {
  const groupedCartItems = _.groupBy(
    _.cloneDeep(cartItemList),
    (cartItem) => cartItem.item.menuId,
  );
  return Object.values(groupedCartItems).map((group) => {
    const [firstItem, ...rest] = group;

    rest.forEach((groupItem) => {
      firstItem.quantity += groupItem.quantity;
      firstItem.totalPrice =
        parseFloat(groupItem.totalPrice) + parseFloat(firstItem.totalPrice);
    });
    return firstItem;
  });
};

/**
 * will return cart item list which all applicable discounts attached with each item
 * both types: PERCENT | AMOUNT
 * @param {list} cartItemList cart items list(FULL OR PARTIAL) which need to attach discount IF APPLICABLE
 * @param {list} discountList discount list given
 */
const getDiscountAttachedCartItems = (cartItemList, discountList) => {
  let discountAttachedCartItems = aggregateLineItemsByItemId(cartItemList);
  discountList.forEach((discount) => {
    discountAttachedCartItems = attachDiscountOnCartItems(
      discountAttachedCartItems,
      discount,
    );
  });
  return discountAttachedCartItems;
};

const filterDiscountsByCriteria = (
  cartItems,
  discountList,
  deliveryTime,
  selectedDeliveryMethod,
) => {
  const activeDiscounts = filterDiscountsByIsActive(discountList);
  const automaticDiscounts = filterDiscountsByIsAutomatic(activeDiscounts);
  const minOrderPriceMatchDiscounts = filterDiscountsByMinOrderPrice(
    cartItems,
    automaticDiscounts,
  );
  const availabilityMatchDiscounts = filterDiscountsByAvailability(
    deliveryTime,
    minOrderPriceMatchDiscounts,
  );
  const deliveryTypeMatchDiscounts = filterDiscountsByDeliveryType(
    selectedDeliveryMethod,
    availabilityMatchDiscounts,
  );
  const normalizedDiscountsByGroup = filterDiscountsByDiscountGroups(
    deliveryTypeMatchDiscounts,
  );
  return normalizedDiscountsByGroup;
};

const calPercentDiscount = (price, percentageDiscountValue) => {
  return parseFloat(
    (parseFloat(price) * (parseFloat(percentageDiscountValue) / 100)).toFixed(
      2,
    ),
  );
};

const calAmountDiscount = (amountDiscountValue) => {
  return parseFloat(amountDiscountValue);
};

// const getCartItemPercentDiscountApplied = (cartItem, discount) => {
//   const updatedItem = { ...cartItem };
//   const discountValue = calPercentDiscount(cartItem.totalPrice, discount.value);
//   updatedItem.totalPrice = parseFloat(cartItem.totalPrice) - discountValue;
//   return updatedItem;
// };

// const getCartItemAmountDiscountApplied = (cartItem, discount) => {
//   const updatedItem = { ...cartItem };
//   updatedItem.totalPrice = parseFloat(cartItem.totalPrice) - parseFloat(discount.value) > 0
//     ? parseFloat(cartItem.totalPrice) - parseFloat(discount.value)
//     : 0;
//   return updatedItem;
// };

const fixPriceDecimals = (price) => parseFloat(parseFloat(price).toFixed(2));

export const getDiscountedPriceCoupon = (totalPrice, discount) => {
  let discountPrice = 0;
  if (discount.type === DISCOUNT_TYPE_AMOUNT) {
    discountPrice = fixPriceDecimals(discount.value);
  } else if (discount.type === DISCOUNT_TYPE_PERCENTAGE) {
    discountPrice = fixPriceDecimals((totalPrice * discount.value) / 100);
  }
  return {
    finalPrice: totalPrice - discountPrice,
    discount: discountPrice,
  };
};

const getAppliedDiscounts = (
  appliedDiscounts,
  applicableCartItemList,
  discountNode,
  discountAmount,
) => {
  if (!_.isEmpty(applicableCartItemList)) {
    const appliedDiscount = { data: discountNode, discountAmount };
    return [...appliedDiscounts, appliedDiscount];
  }
  return appliedDiscounts;
};

const getAllAppliedDiscounts = (
  appliedDiscounts,
  discountNode,
  discountAmount,
) => {
  const appliedDiscount = { data: discountNode, discountAmount };
  return [...appliedDiscounts, appliedDiscount];
};

/**
 * calculate total and other data depending on the APPLICABLE discounts supplied.
 * @param {list} cartItems full cart item list
 * @param {list} discountList FILTERED APPLICABLE discount list
 * @param {string} deliveryTime selected pickup/delivery/dine-in time. format: YYYY-MM-DD HH:mm:ss
 * @param {string} selectedDeliveryMethod  selected delivery method | PICKUP, DINEIN, DELIVER
 */
export const calTotalPriceWithDiscounts = (cartItems, discountList) => {
  const subTotal = cartItems.reduce(
    (acc, item) => acc + parseFloat(item.totalPrice),
    0,
  );

  if (_.isEmpty(discountList)) {
    return {
      discountAttachedCartItems: cartItems,
      totalPrice: subTotal,
      totalDiscount: 0,
      subTotal,
    };
  }
  // attach applicable discounts on each cart Item.
  const discountAttachedCartItems = getDiscountAttachedCartItems(
    cartItems,
    discountList,
  );

  // apply discounts to each item by attached discounts(PERCENT and AMOUNT discounts)

  let totalPriceOfCartItem = subTotal;
  let appliedDiscounts = [];
  // filter item discount list and get total price after item discount applied
  const itemDiscountList = discountList.filter(
    (disc) => disc.applyToCondition === "items",
  );
  itemDiscountList.forEach((discount) => {
    const itemDiscountApplicableCartItemList = cartItems.filter((cartItem) =>
      discount.applyTo.includes(cartItem.item.menuId),
    );

    if (!_.isEmpty(itemDiscountApplicableCartItemList)) {
      const totalPriceOfCartItemsItemDiscountApplicable =
        itemDiscountApplicableCartItemList.reduce(
          (acc, cItem) => parseFloat(cItem.totalPrice) + acc,
          0,
        );
      if (discount.type === DISCOUNT_TYPE_PERCENTAGE) {
        const discountItems = calPercentDiscount(
          totalPriceOfCartItemsItemDiscountApplicable,
          discount.value,
        );
        totalPriceOfCartItem -= discountItems;

        // add discount to applied discount list | we will
        // send these data on confirm request
        appliedDiscounts = getAppliedDiscounts(
          appliedDiscounts,
          itemDiscountApplicableCartItemList,
          discount,
          discountItems,
        );
      } else if (discount.type === DISCOUNT_TYPE_AMOUNT) {
        const discountItems = calAmountDiscount(discount.value);
        const maxDiscount =
          totalPriceOfCartItemsItemDiscountApplicable - discountItems <= 0
            ? totalPriceOfCartItemsItemDiscountApplicable
            : discountItems;
        totalPriceOfCartItem -= maxDiscount;

        // add discount to applied discount list | we will
        // send these data on confirm request
        appliedDiscounts = getAppliedDiscounts(
          appliedDiscounts,
          itemDiscountApplicableCartItemList,
          discount,
          discountItems,
        );
      }
    }
  });

  // filter category discount list and get total price after category discount applied
  const categoryDiscountList = discountList.filter(
    (disc) => disc.applyToCondition === "categories",
  );
  categoryDiscountList.forEach((discount) => {
    const categoryDiscountApplicableCartItemList = cartItems.filter(
      (cartItem) => discount.applyTo.includes(cartItem.item.catId),
    );

    if (!_.isEmpty(categoryDiscountApplicableCartItemList)) {
      const totalPriceOfCartItemsCategoryDiscountApplicable =
        categoryDiscountApplicableCartItemList.reduce(
          (acc, cItem) => parseFloat(cItem.totalPrice) + acc,
          0,
        );
      if (discount.type === DISCOUNT_TYPE_PERCENTAGE) {
        const discountItems = calPercentDiscount(
          totalPriceOfCartItemsCategoryDiscountApplicable,
          discount.value,
        );
        totalPriceOfCartItem -= discountItems;

        // add discount to applied discount list | we will
        // send these data on confirm request
        appliedDiscounts = getAppliedDiscounts(
          appliedDiscounts,
          categoryDiscountApplicableCartItemList,
          discount,
          discountItems,
        );
      } else if (discount.type === DISCOUNT_TYPE_AMOUNT) {
        const discountItems = calAmountDiscount(discount.value);
        const maxDiscount =
          totalPriceOfCartItemsCategoryDiscountApplicable - discountItems <= 0
            ? totalPriceOfCartItemsCategoryDiscountApplicable
            : discountItems;
        totalPriceOfCartItem -= maxDiscount;

        // add discount to applied discount list | we will
        // send these data on confirm request
        appliedDiscounts = getAppliedDiscounts(
          appliedDiscounts,
          categoryDiscountApplicableCartItemList,
          discount,
          discountItems,
        );
      }
    }
  });

  // filter all discount list and get total price after all discount applied
  const totalPriceAfterItemWiseDiscounts = totalPriceOfCartItem;

  const discountListForAllCartItems = discountList.filter(
    (disc) => disc.applyToCondition === "all",
  );

  const percentDiscounts = discountListForAllCartItems.filter(
    (disc) => disc.type === DISCOUNT_TYPE_PERCENTAGE,
  );

  //  cal discount and apply percent discounts
  let totalPrice = totalPriceAfterItemWiseDiscounts;
  percentDiscounts.forEach((disc) => {
    const totalDiscount = calPercentDiscount(totalPrice, disc.value);
    totalPrice -= totalDiscount;

    // add discount to applied discount list | we will
    // send these data on confirm request
    appliedDiscounts = getAllAppliedDiscounts(
      appliedDiscounts,
      disc,
      totalDiscount,
    );
  });

  const amountDiscounts = discountListForAllCartItems.filter(
    (disc) => disc.type === DISCOUNT_TYPE_AMOUNT,
  );

  //  cal discount and apply amount discounts
  amountDiscounts.forEach((disc) => {
    const discountAmount = calAmountDiscount(disc.value);
    totalPrice -= discountAmount;

    // add discount to applied discount list | we will
    // send these data on confirm request
    appliedDiscounts = getAllAppliedDiscounts(
      appliedDiscounts,
      disc,
      discountAmount,
    );
  });

  totalPrice = totalPrice > 0 ? totalPrice : 0;
  const totalDiscount =
    parseFloat(subTotal.toFixed(2)) - parseFloat(totalPrice.toFixed(2));
  return {
    totalPrice,
    totalDiscount,
    subTotal,
    discountAttachedCartItems,
    orderDiscounts: discountListForAllCartItems,
    appliedDiscounts,
  };
};

/**
 * get all discount list which applies to all the items in the cart.
 * @param {list} cartItems full cart item list
 * @param {list} discountList full discount items list
 * @param {string} deliveryTime selected pickup/delivery/dine-in time. format: YYYY-MM-DD HH:mm:ss
 * @param {string} selectedDeliveryMethod  selected delivery method | PICKUP, DINEIN, DELIVER
 */
export function getDiscountListApplicableForAllCartItems(
  cartItems,
  discountList,
  deliveryTime,
  selectedDeliveryMethod,
) {
  const applicableDiscounts = filterDiscountsByCriteria(
    cartItems,
    discountList,
    deliveryTime,
    selectedDeliveryMethod,
  );
  return applicableDiscounts.filter((disc) => disc.applyToCondition === "all");
}

export const calDiscountedPriceCoupon = (
  cartItems,
  couponNode,
  deliveryTime,
  selectedDeliveryMethod,
) => {
  const activeDiscounts = filterDiscountsByIsActive([couponNode]);
  if (_.isEmpty(activeDiscounts)) {
    return { error: true, reason: "Coupon is not valid" };
  }

  const minOrderPriceMatchDiscounts = filterDiscountsByMinOrderPrice(
    cartItems,
    activeDiscounts,
  );
  if (_.isEmpty(minOrderPriceMatchDiscounts)) {
    return { error: true, reason: "Minimum order price does not match" };
  }

  const availabilityMatchDiscounts = filterDiscountsByAvailability(
    deliveryTime,
    minOrderPriceMatchDiscounts,
  );
  if (_.isEmpty(availabilityMatchDiscounts)) {
    return { error: true, reason: "Coupon not available for selected time" };
  }

  const deliveryTypeMatchDiscounts = filterDiscountsByDeliveryType(
    selectedDeliveryMethod,
    availabilityMatchDiscounts,
  );
  if (_.isEmpty(deliveryTypeMatchDiscounts)) {
    return {
      error: true,
      reason: "Coupon not available for selected delivery type",
    };
  }

  return calTotalPriceWithDiscounts(cartItems, deliveryTypeMatchDiscounts);
};

// MAIN FUNC TO CAL
export const calDiscountedPriceAutomatic = (
  cartItems,
  discountList,
  deliveryTime,
  selectedDeliveryMethod,
) => {
  const applicableDiscounts = filterDiscountsByCriteria(
    cartItems,
    discountList,
    deliveryTime,
    selectedDeliveryMethod,
  );

  return calTotalPriceWithDiscounts(cartItems, applicableDiscounts);
};
